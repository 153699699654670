import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "acciondigital-basic-theme/src/components/layout"
import SEO from "acciondigital-basic-theme/src/components/seo"
import InfoContacto from "acciondigital-basic-theme/src/components/InfoContacto"
import FormBasicoNetlify from "acciondigital-basic-theme/src/components/FormBasicoNetlify"
import ImagenUbicacionMaps from "acciondigital-basic-theme/src/components/ImagenUbicacionMaps"
export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "portada.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

// calle independencia #67 segunda sección 70000
// planta alta oficina #3
const dias = [{ "lun-dom:": ["09:00am–09:00pm"] }]
const direccion = ["Av. Oaxaca #21","Primera Sección", "Juchitán, Oax"]
const telefono = "971 110 4164"
const irMapsLink =
  "https://www.google.com/maps/dir//La+Brigitte+Plaza/data=!4m8!4m7!1m0!1m5!1m1!1s0x859555c2a669acb9:0xc730db5d2ead998!2m2!1d-95.0237142!2d16.442236899999997"

const asuntos = [
  {
    value: "consultas",
    texto: "Consultas.",
  },
  {
    value: "quejas",
    texto: "Quejas.",
  },
  {
    value: "comentarios",
    texto: "Comentarios.",
  },
]

export default function Home({ data }) {
  // const texto = "hola world!"
  // const goldenTexto = gold(texto)
  const { placeholderImage } = data
  return (
    <Layout
    >
      <SEO
        title="Inicio"
        imageSource={placeholderImage.childImageSharp.fluid.src}
      />
      {/* <h1 className="text-center p-3">
        <strong>MEXICANISSSIMA</strong>
      </h1> */}

      <Image fluid={placeholderImage.childImageSharp.fluid} />

      <p className="text-center p-2 blockquote">
        Tienda de frangancias y novedades.
      </p>
      <ImagenUbicacionMaps
        titulo='Mapa con la ubicación de Plaza "La Brigitte".'
        linkMaps="https://www.google.com/maps/place/La+Brigitte+Plaza/data=!3m1!4b1!4m2!3m1!1s0x859555c2a669acb9:0xc730db5d2ead998"
        linkImgMaps="https://maps.googleapis.com/maps/api/staticmap?scale=1&size=1600x900&style=feature:poi.business|visibility:off&style=feature:water|visibility:simplified&style=feature:road|element:labels.icon|visibility:off&style=feature:road.highway|element:labels|saturation:-90|lightness:25&format=jpg&language=es-419&region=MX&markers=color:0x48487f|16.4422369,-95.0237142&zoom=16&client=google-presto&signature=E0PQFehpxr7FZwEtFTeoGmsf57s"
      />

      <InfoContacto
        dias={dias}
        direccion={direccion}
        telefono={telefono}
        irMapsLink={irMapsLink}
      />
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div>
              <h2>Escríbenos</h2>
            </div>
            <FormBasicoNetlify nombre="contacto-v1" asuntos={asuntos} />
          </div>
        </div>
      </div>
      {/* <div>{texto}</div>
      <div>{goldenTexto}</div>
      <ExampleComponent2 text={goldenTexto}>
        <div>aca</div>
      </ExampleComponent2>
      <Foo text="Foo Props" />
      <Bar text="Bar Props" />
      <Nacho text="Nacho Props" /> */}
    </Layout>
  )
}
